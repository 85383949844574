/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .about {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0.5), rgba(183, 183, 183, 0.5)), url('./pics/about-xs.jpg');
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .about {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0.5), rgba(183, 183, 183, 0.5)), url('./pics/about-sm.jpg');
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .about {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0.5), rgba(183, 183, 183, 0.5)), url('./pics/about-md.jpg');
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .about {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0.5), rgba(183, 183, 183, 0.5)), url('./pics/about-lg.jpg');
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .about {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0.5), rgba(183, 183, 183, 0.5)), url('./pics/about-xl.jpg');
  }
}
