.title-for-google {
  display: none;
}
h2.title {
  font-size: 9vw;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
h2 a {
  text-decoration: none;
}
h2 a:hover {
  text-decoration: underline;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .contact {
    background-image: url('./pics/contact-xs.jpg');
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact {
    background-image: url('./pics/contact-sm.jpg');
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact {
    background-image: url('./pics/contact-md.jpg');
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact {
    background-image: url('./pics/contact-lg.jpg');
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .contact {
    background-image: url('./pics/contact-xl.jpg');
  }
}
