h1.title {
  font-size: 9vw;
  text-shadow: 1px 1px 3px #cacaca;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .home {
    background-image: linear-gradient(to bottom right, rgba(27, 172, 255, 0.5), rgba(220, 66, 37, 0.5)), url('./pics/home-xs.jpg');
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .home {
    background-image: linear-gradient(to bottom right, rgba(27, 172, 255, 0.5), rgba(220, 66, 37, 0.5)), url('./pics/home-sm.jpg');
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .home {
    background-image: linear-gradient(to bottom right, rgba(27, 172, 255, 0.5), rgba(220, 66, 37, 0.5)), url('./pics/home-md.jpg');
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .home {
    background-image: linear-gradient(to bottom right, rgba(27, 172, 255, 0.5), rgba(220, 66, 37, 0.5)), url('./pics/home-lg.jpg');
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .home {
    background-image: linear-gradient(to bottom right, rgba(27, 172, 255, 0.5), rgba(220, 66, 37, 0.5)), url('./pics/home-xl.jpg');
  }
}
